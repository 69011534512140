export default {
    "active": false,
    "selectedPort": "",
    "channel": "all",
    "ports": []
};

const defaultClockState = {
    tempo: null,
    sync: 0,
    quarterNoteDuration: 0
};

export {
    defaultClockState
};
