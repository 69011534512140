export const MODULATOR_MODE = {
    "GLOBAL": "global",
    "VOICE": "voice",
    "RETRIGGER": "retrigger"
};

export const MODULATOR_TYPE = {
    "ENVELOPE": "env",
    "LFO": "lfo",
    "MORSE": "morse",
    "STEPS": "steps"
};
