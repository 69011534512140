const prefix = "STEPS_";

export const GENERATE_SEQUENCE = prefix + "GENERATE_SEQUENCE";
export const GENERATOR_SELECT = prefix + "GENERATOR_SELECT";
export const GLIDE_AT_CHANGE = prefix + "GLIDE_AT_CHANGE";
export const GLIDE_AT_EVERY = prefix + "GLIDE_AT_EVERY";
export const GLIDE_AT_FALLING = prefix + "GLIDE_AT_FALLING";
export const GLIDE_AT_RISING = prefix + "GLIDE_AT_RISING";
export const GLIDE_INVERT = prefix + "GLIDE_INVERT";
export const GLIDE_MODE_CHANGE = prefix + "GLIDE_MODE_CHANGE";
export const GLIDE_NONE = prefix + "GLIDE_NONE";
export const GLIDE_SHIFT = prefix + "GLIDE_SHIFT";
export const GLIDE_SLOPE_CHANGE = prefix + "GLIDE_SLOPE_CHANGE";
export const GLIDE_TIME_CHANGE = prefix + "GLIDE_TIME_CHANGE";
export const INVERT_VALUES = prefix + "INVERT_VALUES";
export const MAX_VALUE_DECREASE = prefix + "MAX_VALUE_DECREASE";
export const MAX_VALUE_INCREASE = prefix + "MAX_VALUE_INCREASE";
export const REVERSE = prefix + "REVERSE";
export const SEQUENCE_SHIFT = prefix + "SEQUENCE_SHIFT";
export const STEP_ADD = prefix + "STEP_ADD";
export const STEP_DELETE = prefix + "STEP_DELETE";
export const STEP_GLIDE_TOGGLE = prefix + "STEP_GLIDE_TOGGLE";
export const STEP_VALUE_CHANGE = prefix + "STEP_VALUE_CHANGE";
