const prefix = "OSCILLATOR_";
export const DETUNE_CHANGE = prefix + "DETUNE_CHANGE";
export const MIX_CHANGE = prefix + "MIX_CHANGE";
export const WRAPPER_CHANGE = prefix + "RESONANCE_CHANGE";
export const WAVEFORM_CHANGE = prefix + "WAVEFORM_CHANGE";
export const RESONANCE_FACTOR_CHANGE = prefix + "RESONANCE_FACTOR_CHANGE";
export const MODE_CHANGE = prefix + "MODE_CHANGE";
export const HARMONICS_MIX_CHANGE = prefix + "HARMONICS_MIX_CHANGE";
export const RESONANCE_TOGGLE = prefix + "RESONANCE_TOGGLE";

